import React, { useState } from 'react';

function MainAppSD() {
    const [showForm, setShowForm] = useState(false);

    const handleButtonClick = () => {
      setShowForm(true);
    };
  
    return (
      <div>
        <h1>Пример приложения React</h1>
        <div>
          <button onClick={handleButtonClick}>Кнопка 1</button>
          <button>Кнопка 2</button>
          <button>Кнопка 3</button>
        </div>
        {showForm && (
          <div>
            <form>
              <label>
                Введите что-нибудь:
                <input type="text" />
              </label>
              <button type="submit">Отправить</button>
            </form>
            <div>
              <input type="text" placeholder="Заблокированный инпут 1" disabled />
              <input type="text" placeholder="Заблокированный инпут 2" disabled />
              <input type="text" placeholder="Заблокированный инпут 3" disabled />
            </div>
          </div>
        )}
      </div>
    );
}

export default MainAppSD;